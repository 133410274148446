
    import {computed, defineComponent, onMounted, reactive, toRefs,ref, pushScopeId} from 'vue';
    import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons-vue';
    import PageUtil from "@/utils/page_util";
    import {message} from "ant-design-vue/es";
    import ApiSsy from "@/request/apis/api_ssy";
    import UploadComponent from "@/components/UploadComponent.vue"
    import dayjs, { Dayjs } from 'dayjs';
    import UploadUtil from "@/utils/upload_util";
    import {baseUrl} from "@/utils/config";
    import EditorComponent from '@/components/editor/Editor.vue'
    interface IState {
        list: any,
        visible: any,
        title: any,
        total: any,
        searches: any
        type: any,
        formState:any,
        treeData:any,
        xuanxiangList:any,
        templateList:any,
        filelist:any,
        visibleShow:any,
        detailData:any,
        tagList:any
    }

    export default defineComponent({
        name: 'salaryRange',
        components: {
            MinusCircleOutlined,
            PlusOutlined,
            UploadComponent,
            EditorComponent
        },
        setup() {
            let state: IState = reactive({
                visible: false,
                title: '',
                name: '',
                treeData:[],
                tagList:[],
                list: [],
                visibleShow:false,
                detailData:'',
                total: 0,
                xuanxiangList:[],
                filelist:[],
                searches:{
                    page:PageUtil.pageNo,
                    limit:PageUtil.pageSize,
                    tag_id:undefined,
                    title:'',
                    status:undefined
                },
                type: '',
                formState:'' ,
                templateList:[],
            })
            const columns = [
                {
                    title: 'id',
                    dataIndex: 'id',
                    key: 'id',
                    align:'center'
                },
                {
                    title: '活动名称',
                    dataIndex: 'title',
                    key: 'title',
                    align:'center'
                },
                {
                    title: '活动标识',
                    dataIndex: 'tag',
                    key: 'tag',
                    align:'center'
                },
                // {
                //     title: '活动内容',
                //     dataIndex: 'content',
                //     key: 'content',
                //     align:'center'
                // },
                {
                    title: '排序',
                    dataIndex: 'sort',
                    key: 'sort',
                    align:'center'
                },
                {
                    title: '开始时间',
                    dataIndex: 'start_time',
                    key: 'start_time',
                    align:'center'
                },
                {
                    title: '结束时间',
                    dataIndex: 'end_time',
                    key: 'end_time',
                    align:'center'
                },
                {
                    title: '状态',
                    dataIndex: 'status',
                    key: 'status',
                    align:'center'
                },
                 {
                    title: '创建时间',
                    dataIndex: 'create_time',
                    key: 'create_time',
                    align:'center'
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    key: 'action',
                    width: 250,
                    align:'center'
                },
            ]
            const editorComponentRef = ref()
            const rules = {
                tag: [{required: true, message: "请输入活动标识"},],
                title: [{required: true, message: "请输入活动标题"},],
                content: [{required: true, message: "请输入活动内容"},],
                images: [{required: true, message: "请上传图片"},],
                // videos: [{required: true, message: "请上传视频"},],
                sort: [{required: true, message: "请输入排序"},],
                start_time: [{required: true, message: "请选择开始时间"},],
                end_time: [{required: true, message: "请选择结束时间"},],
                status: [{required: true, message: "请输入状态"},],
            }


            const add = () => {
                gettags()
                state.title = '新增活动'
                state.visible = true
                state.type = 1
                state.filelist = []
                state.formState={
                    "tag":"",
                    "title":"",
                    "content":"",
                    "images":[],
                    "videos":[],
                    "sort":'',
                    "options":[],
                    "start_time":"",
                    "end_time":"",
                    "tag_ids":[],
                    "template_id":undefined
                }
                // editorComponentRef.value.clear()
                editorComponentRef.value.setHtmlText('<p><br></p>')
            }



            const edit = (item) => {
                gettags()
                state.title = "编辑活动"
                const val =JSON.parse(JSON.stringify(item))
                state.formState={
                    "id":val.id,
                    "tag":val.tag,
                    "title":val.title,
                    "content":val.content,
                    "images":[],
                    "videos":val.videos,
                    "sort":val.sort,
                    "options":[],
                    "status":val.status,
                    "start_time":dayjs(val.start_time),
                    "end_time":dayjs(val.start_time),
                    "template_id":val.template_id,
                    "tag_ids":[]
                }
                val.formOptions.forEach((item,index)=>{
                    state.formState.options.push(item.option_id)
                })
                val.tags.forEach((item,index)=>{
                    state.formState.tag_ids.push(item.tag_id)
                })
                val.images.forEach((item,index)=>{
                    console.log(item)
                    const obj = {
                        uid: index,
                        name: index,
                        status: 'done',
                        url: item
                    }
                    state.formState.images.push(obj)
                 })
                state.filelist = state.formState.images
                console.log(state.formState.images,"aaaa")
                state.visible = true
            }

            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否显示pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize
                state.searches.page = pages.current
                getList()
            };

            const getList = async () => {
                const params = {...state.searches}
                const res = await ApiSsy.activityIndex(params)
                if (res && res.error_code == 0) {
                    const data: any = res.data
                    state.list = data.list
                    state.total = data.count
                }
            }

            const getTemplate = async () => {
                const params = {page:1,limit:999}
                const res = await ApiSsy.templateIndex(params)
                if (res && res.error_code == 0) {
                    const data: any = res.data
                    state.templateList = data.list
                }
            }


            const getoptionList = async () => {
                const params = {page:1,limit:999}
                const res = await ApiSsy.optionList(params)
                if (res && res.error_code == 0) {
                    const data: any = res.data
                    state.xuanxiangList = data.list
                }
            }

            const gettags = async () => {
                const params = {page:1,limit:999}
                const res = await ApiSsy.tagsIndex(params)
                if (res && res.error_code == 0) {
                    const data: any = res.data
                    state.tagList = data.list
                }
            }
            const formRef = ref();
            const onSubmit = async () => {
                state.formState.content = editorComponentRef.value.getHtmlText()
                formRef.value.validate() .then(async() => {
                        const params = {...state.formState}
                        const obj = [] as any
                        params.images.forEach((item,index)=>{
                            obj.push(item.url)
                        })
                        params.images = obj
                        params.start_time = dayjs(params.start_time).format('YYYY-MM-DD HH:mm:ss')
                        params.end_time = dayjs(params.end_time).format('YYYY-MM-DD HH:mm:ss')
                        params.status = 1
                        const res = await ApiSsy.activityStore(params)
                        if(res.error_code == 0){
                            state.visible = false
                            message.success(res.msg)
                            await getList()
                        }else{
                            message.error(res.msg)
                        }
                })
                    .catch((error: any) => {
                        console.log("error", error)
                    })
            }


            const onDel = async (val) => {
                const params = {id:val.id}
                const res = await ApiSsy.activitydestroy(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        await getList()
                        message.success(res.msg)
                    }else{
                        message.error(res.msg)
                    }
                }
            }

            const onStatus = async (val) => {
                const params = {id:val.id}
                const res = await ApiSsy.activityDisableAndEnable(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        await getList()
                        message.success(res.msg)
                    }else{
                        message.error(res.msg)
                    }
                }
            }

            const uploadHeaders = UploadUtil.uploadHeaders()
            let uploadAction = baseUrl + '/admin/upload/image'
            let uploadFormData = {cid: 10}
            const handleChange2 = ({file}: any) => {
                if (file.status === 'done' && file.response) {
                    const res = file.response
                    if (res && res.error_code === 0) {
                        const data = res.data
                        const obj = {
                            uid: '-1',
                            name: 'image.png',
                            status: 'done',
                            url: data.uri
                        }
                        state.formState.images.push(obj)
                    }
                }
            }

            const handleChange3 = ({file}: any) => {
                if (file.status === 'done' && file.response) {
                    const res = file.response
                    if (res && res.error_code === 0) {
                        const data = res.data
                        console.log(data)
                        state.formState.videos[0]= data.uri
                    }
                }
            }

            const onMediaChange = (url: string) =>{
                state.formState.videos[0]= url
            }
            const remove2 = (file) =>{
                console.log(file,"1231")
                for (var key in state.filelist) {
                    if (state.filelist[key].url === file.url) {
                        state.filelist.splice(key, 1)
                    }
                }
                state.formState.images = state.filelist
            }
            const getdetail = async (val) => {
                const params = {id:val.id}
                const res = await ApiSsy.activityShow(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                       const data = res.data
                       state.detailData = data
                       state.visibleShow = true
                    }else{
                        message.error(res.msg)
                    }
                }
            }

            const onSearch = () =>{
                state.searches.page = 1
                getList()
            }

            const onReset = () =>{
                state.searches.page = 1
                state.searches.tag_id = undefined
                state.searches.title = ''
                state.searches.status = undefined
                getList()
            }

            onMounted(() => {
                getList()
                gettags()
                getTemplate()
                getoptionList()
                
            })

            return {
                ...toRefs(state),
                columns,
                onSearch,
                onReset,
                handleChange3,
                open,
                getdetail,
                onMediaChange,
                dayjs,
                remove2,
                getoptionList,
                gettags,
                formRef,
                getTemplate,
                onStatus,
                uploadHeaders,
                uploadAction,
                uploadFormData,
                handleChange2,
                onSubmit,
                rules,
                onDel,
                edit,
                add,
                editorComponentRef,
                pagination,
                handleTableChange
            }
        }
    });
